/* eslint-disable no-unused-vars */
import React from 'react';
import ContactForm from '../ContactFrom/ContactForm'


const Contactpage = () => {

    return(
        <section className="wpo-contact-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-10 offset-lg-1">
                        <div className="office-info">
                            <div className="row">
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-maps-and-flags"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>住所</h2>
                                            <p>会場：にこわ新小岩 3階</p>
                                            <p>東京都葛飾区西新小岩4-33-2</p>
                                            <p>JR「新小岩」駅北口から徒歩8分</p>
                                        </div>
                                    </div>
                                </div> 
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-email"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>連絡先 </h2>
                                            <p>suzuya@tea.ocn.ne.jp</p>
                                            <p>090-3334-3566</p>
                                        </div>
                                    </div>
                                </div> 
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-phone-call"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>活動時間</h2>
                                            <p>毎週金曜日 19:00~20:45</p>
                                            <p>祝日休み</p>
                                            <p>費用：無料</p>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        {/* <div className="wpo-contact-title">
                            <h2>お問い合わせ</h2>
                            <p>申し訳ございません、本機能開発中のために、現在ご利用できません。</p>
                        </div>
                        <div className="wpo-contact-form-area">
                            <ContactForm/>
                        </div> */}
                    </div>                
                </div>
            </div> 
            <section className="wpo-contact-map-section">
                <div className="wpo-contact-map">
                    <iframe title='comtact-map' src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3239.305631379006!2d139.85110127578938!3d35.71870127257509!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzXCsDQzJzA3LjMiTiAxMznCsDUxJzEzLjIiRQ!5e0!3m2!1sja!2sjp!4v1704368725406!5m2!1sja!2sjp"></iframe>
                </div>
            </section>
        </section>
     )
        
}

export default Contactpage;
